export const Graduating_Years = Array.from({ length: new Date().getUTCFullYear() - 2018 }, (_, i) => 2021 + i);

export type CurrentUser = {
  id: number;
  email: string;
  is_verify: boolean;
  gender: string;
  firstname: string;
  lastname: string;
  middlename: string;
  impairment: string;
  auth_challenge: string;
  dob_string: string;
  graduting_class: string | null;
  school: string | null;
  role: number;
  linkedin_url: string | null;
  phone_number: string | null;
}
